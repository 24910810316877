const handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;

    let value = null;

    switch (target.type) {
        case "checkbox":
            value = target.checked;
            break;
        case "radio":
        case "switch":
            value = target.id;
            break;
        case "select-multiple":
            value = [];
            var options = target.options;
            var opt;
            for (var i = 0; i < options.length; i++) {
                opt = options[i];

                if (opt.selected) {
                    value.push(opt.value);
                }
            }
            break;
        default:
            value = target.value;
    }
    return {name: name, value: value};
};

const isValid = (value, isNumberCheck = false) => {
    return value !== undefined && value !== null && value.length > 0 &&
        (!isNumberCheck || /(\d+$)|(\d+\.\d+$)/.test(value)); // проверяем, value - целое или дробное число
};

// Проверка заполненности полей
const checkRequired =(required, state) => {
    for (let comp of required) {
        if (comp.props.required && !isValid(state[comp.props.name]))
            return false;
    }
    return true;
};

// Формирует и возвращает модель
const getModel = (model, state) =>
{
    let res = {};

    for (let comp of model) {
        const name = comp.props.name;
        res[name] = state[name];
    }

    return res;
}

export {
    handleInputChange,
    checkRequired,
    getModel,
    isValid
};