import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { AsyncTypeahead, Menu, MenuItem, Highlighter } from "react-bootstrap-typeahead";
import { handleInputChange, isValid } from "../../utils/forms";
import ColInline from "./ColInline";
import SuperLabel from "./SuperLabel";
import Uniput from "./Uniput";

const URI = "/api/util/getgeosuggest";

const DEFAULT_COUNTRY = [{ name: "Россия", itemId: "RU", regId: "" }];

class GeoTypeahead extends React.Component {
    static getParentId(parentValue) {
        if (isValid(parentValue)) return parentValue[0].itemId;
        else return "";
    }

    constructor(props) {
        super(props);

        this.changeValue = this.changeValue.bind(this);
        this.renderMenu = this.renderMenu.bind(this);
        this.emptyLabel = "Ничего не найдено";
    }

    state = {
        typeahead: {
            highlightOnlyResult: true,
            selectHintOnEnter: true,
            flip: true,
            align: "left",
            searchText: "Загрузка списка...",
            promptText: " ",
            labelKey: "name",
            useCache: false,
            id: "id",
        },
        options: [],
        isLoading: false,
        cityId: "",
        isAllowedCountry: true, // выводим посказки адреса?
    };

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.type === "address" && nextProps.parentValue.length !== 0) {
            if (this.state.cityId !== nextProps.parentValue[0].v) {
                this.setState({ options: [], cityId: nextProps.parentValue[0].v });
            }
        }

        return true;
    }

    changeValue(event) {
        if (event[0] === this.emptyLabel) return;

        const e = {
            target: {
                name: this.props.name,
                type: "GeoTypeaheadVS",
                value: event,
            },
        };

        // если вводим адрес вручную, обрабатываем Uniput
        if (this.props.type === "address" && !this.state.isAllowedCountry) {
            const { value } = handleInputChange(event);
            if (this.state.options.length === 0) {
                //this.state.options = [{ n: "" }];
            }
            //this.state.options[0].n = value;
            e.target.value = [{ n: value }];
            //this.props.onChange(e);
            return;
        }

        if (event.length === 0) {
            this.props.onChange(e);
            return;
        }

        if (this.props.type === "address") {
            let selected = event[0];

            try {
                // Делаем дополнительный запрос для получения всех параметров адреса

                let fiasId = selected.parentId;

                if (fiasId === null) fiasId = this.props.parentValue[0].v;

                this.makeAndHandleRequest(selected.uv, fiasId, 1).then((options) => {
                    e.target.value = [
                        {
                            n: options[0].n,
                            v: options[0].v,
                            uv: options[0].uv,
                            kadIn: options[0].kadIn,
                            parentId: selected.parentId,
                            parentName: selected.parentName,
                            regId: selected.regId,
                        },
                    ];

                    this.setState({ options: options });
                    this.props.onChange(e);
                });
            } catch (err) {
                console.log(err);
                console.log(event);
            }
        } else this.props.onChange(e);
    }

    _handleSearch = (query) => {
        this.setState({ isLoading: true, options: [] });
        const parentValue = GeoTypeahead.getParentId(this.props.parentValue);
        if (parentValue === "" && this.props.parentValue !== undefined) return;

        this.makeAndHandleRequest(query, parentValue, 7).then((options) => {
            this.setState({
                isLoading: false,
                options: options,
            });
        });
    };

    makeAndHandleRequest(query, parentId, qty) {
        const type = this.props.type;
        let partParentName = "";

        if (
            this.props.parentValue !== undefined &&
            this.props.parentValue !== null &&
            this.props.parentValue.length !== 0 &&
            this.props.parentValue[0] !== undefined &&
            this.props.parentValue[0] !== null
        ) {
            partParentName = `&parentName=${encodeURI(this.props.parentValue[0].name)}`;
        }

        let SEARCH_URI = `${URI}?prompt=${encodeURI(query)}&type=${type}&qty=${qty}&parentId=${parentId}${partParentName}`;

        return fetch(SEARCH_URI).then((resp) => resp.json());
    }

    renderMenu(results, menuProps) {
        if (!results.length && (this.emptyLabel === undefined || this.emptyLabel === undefined || this.emptyLabel === "")) {
            return null;
        }
        return (
            <Menu {...menuProps}>
                {results.length !== 0 &&
                    results.map((result, index) => (
                        <MenuItem option={result} position={index} key={index}>
                            <Highlighter key={"name"} search={result.name}>
                                {result.name}
                            </Highlighter>
                        </MenuItem>
                    ))}
                {results.length === 0 && (
                    <MenuItem option={this.emptyLabel} key={0}>
                        {this.emptyLabel}
                    </MenuItem>
                )}
            </Menu>
        );
    }

    render() {
        const {
            type,
            inputWidth,
            labelWidth,
            totalWidth,
            label,
            small,
            required,
            inline,
            value,
            showError,
            disabled,
            ...nonTypeaheadProps
        } = this.props;

        delete nonTypeaheadProps.onChange;
        delete nonTypeaheadProps.parentValue;

        const addClass = small && "form-control-sm";
        // передан пустой parentValue
        const parentIsEmpty =
            this.props.parentValue !== undefined && this.props.parentValue !== null && this.props.parentValue.length === 0;
        let minLength;

        switch (type) {
            case "country":
                minLength = 2;
                break;
            case "city":
                minLength = 3;
                break;
            case "address":
                minLength = 4;
                break;
            default:
        }

        // если передана страна, для которой не выводятся подсказки адреса, рендериим Uniput
        if (
            this.props.type === "address" &&
            this.props.country.length !== 0 &&
            !this.props.allowedCountries.includes(this.props.country[0].name)
        ) {
            //this.state.isAllowedCountry = false;
            let currentValue = "";
            if (
                value !== undefined &&
                value !== null &&
                typeof value === "object" &&
                value.constructor === Array &&
                value.length !== 0
            ) {
                currentValue = value[0].name;
            }

            return (
                <Uniput
                    onChange={this.changeValue}
                    type="address"
                    small
                    name="address"
                    label="Адрес"
                    value={currentValue}
                    inline
                    required={required}
                    disabled={disabled}
                />
            );
        }
        //this.state.isAllowedCountry = true;
        return (
            <Fragment>
                <SuperLabel caption={label} labelWidth={labelWidth} required={required} small={small} />
                <ColInline
                    inline={inline}
                    inputWidth={inputWidth}
                    emptyWidth={label !== "" ? totalWidth - inputWidth - labelWidth : 0}
                >
                    <AsyncTypeahead
                        {...this.state.typeahead}
                        options={parentIsEmpty ? [] : this.state.options}
                        minLength={minLength}
                        filterBy={() => true}
                        onChange={this.changeValue}
                        onSearch={this._handleSearch}
                        bgSize={small ? "sm" : ""}
                        inputProps={{ className: addClass }}
                        isInvalid={required && showError && !isValid(value)}
                        isLoading={this.state.isLoading}
                        selected={value}
                        disabled={disabled}
                        renderMenu={this.renderMenu}
                        {...nonTypeaheadProps}
                    />
                </ColInline>
            </Fragment>
        );
    }

    static propTypes = {
        type: PropTypes.oneOf(["address", "city", "country"]).isRequired,
        label: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        value: PropTypes.any,
        inline: PropTypes.bool,
        parentValue: PropTypes.array,
        inputWidth: PropTypes.number,
        labelWidth: PropTypes.number,
        totalWidth: PropTypes.number,
    };

    static defaultProps = {
        inline: false,
        labelWidth: 12,
        totalWidth: 12,
    };
}

export default GeoTypeahead;

export { DEFAULT_COUNTRY };
