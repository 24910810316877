import React from "react";
import { Row, Col } from "reactstrap";
import SwitchInput from "../../core/components/inputs/SwitchInput";
import TariffList from "./TariffList";
import TariffSettings from "./TariffSettings";

class Tariffs extends React.Component {
    render() {
        const {
            handleChangeTariff,
            handleChange,
            handleModel,
            hideTariffName,
            chooseFromList,
            tariffs,
            showCourierDelivery,
            showPvzDelivery,
            showPostDelivery,
            showEconomDelivery,
            showExpressDelivery,
            createOrdersFromDoor,
        } = this.props;

        return (
            <Row className="gray_bg p-3">
                <Col>
                    <Row>
                        <h3>Тарифы</h3>
                    </Row>
                    {!chooseFromList && (
                        <TariffSettings
                            showCourierDelivery={showCourierDelivery}
                            showPvzDelivery={showPvzDelivery}
                            showPostDelivery={showPostDelivery}
                            showEconomDelivery={showEconomDelivery}
                            showExpressDelivery={showExpressDelivery}
                            createOrdersFromDoor={createOrdersFromDoor}
                            chooseFromList={chooseFromList}
                            handleChange={handleChange}
                            handleModel={handleModel}
                        />
                    )}
                    <Row className="py-1 px-0 form-check form-switch">
                        <SwitchInput
                            className="ps-0 ms-0 py-2"
                            value={hideTariffName}
                            onChange={handleChange}
                            name="hideTariffName"
                            label="Не показывать название тарифов при выборе"
                            id="hideTariffName"
                            ref={handleModel}
                        />
                    </Row>
                    <Row className="small_text text-muted">
                        Если установить эту опцию, то название тарифов будет скрыто и покупатель увидит только сроки и цену.
                    </Row>
                    <Row className="py-1 px-0 form-check form-switch">
                        <SwitchInput
                            className="ps-0 ms-0 py-2"
                            value={chooseFromList}
                            onChange={handleChange}
                            name="chooseFromList"
                            label="Выбрать тарифы из списка"
                            id="chooseFromList"
                            ref={handleModel}
                        />
                    </Row>
                    {chooseFromList && <TariffList tariffs={tariffs} handleChangeTariff={handleChangeTariff} />}
                </Col>
            </Row>
        );
    }
}

export default Tariffs;
