import React, { Component } from "react";
import { Route } from "react-router";
import { CDEKSettings } from "./modules/CDEK/CDEKSettings";
import { Container } from "reactstrap";
import { Default } from "./modules/Default";

export default class App extends Component {
    render() {
        return (
            <div className="versta_bg">
                <Container>
                    <Route exact path="/CDEK" component={CDEKSettings} />
                    <Route exact path="/" component={Default} />
                </Container>
            </div>
        );
    }
}
