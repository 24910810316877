import React from "react";
import PropTypes from "prop-types";
import { Label } from "reactstrap";

// ЭТО НЕ ИСПОЛЬЗОВАТЬ !!!!!!!!!!!!!!!!!!!!!!!!!
// БУДЕТ УДАЛЕНО В СЛЕДУЮЩИХ РЕЛИЗАХ !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

class SuperLabel extends React.Component {
    render() {
        const addClass = this.props.small && "form-control-sm";

        if (this.props.caption !== undefined && this.props.caption !== "") {
            const className =
                this.props.className !== undefined && this.props.className !== null ? this.props.className + " " : "";
            return (
                <Label
                    onClick={this.props.onClick}
                    className={`${className}text-nowrap ps-0 col-${this.props.labelWidth} ${addClass}`}
                >
                    {this.props.caption}:{this.props.required ? <i className="text-danger">*</i> : ""}
                </Label>
            );
        } else return "";
    }

    static propTypes = {
        caption: PropTypes.string.isRequired,
        required: PropTypes.bool,
        colWidth: PropTypes.number,
        small: PropTypes.bool,
        labelWidth: PropTypes.number,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        required: false,
        labelWidth: 4,
        small: false,
    };
}

export default SuperLabel;
