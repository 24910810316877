import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Label, Tooltip } from "reactstrap";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

class UniLabel extends React.Component {
    constructor(props) {
        super(props);

        this.id = _.uniqueId("label");
        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
        };
    }

    componentDidMount() {
        // генерируем случайный id для Label. Это нужно для корректной работы Tooltip
        this.id = _.uniqueId("label");
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    }

    render() {
        const { className, labelWidth, labelWidthLg, caption, required, colon, tooltip, tooltipId, labelLight, ...labelProps } =
            this.props;

        if (caption !== "") {
            let id = tooltipId !== undefined ? tooltipId : this.id;
            return (
                <Label
                    className={`text-nowrap ps-0  ${labelWidth !== undefined ? "col-" + labelWidth : ""} ${
                        labelWidthLg !== undefined ? "col-lg-" + labelWidthLg : ""
                    } ${className !== undefined ? className : ""} ${labelLight ? "fw-normal" : ""}`}
                    id={id}
                    {...labelProps}
                >
                    {caption}
                    {colon && ":"}
                    {tooltip !== null && (
                        <Fragment>
                            &nbsp;
                            {
                                // <FontAwesomeIcon icon="info-circle" color="#1ca4e8" size="sm" />
                            }
                            <Tooltip
                                placement="top"
                                target={id}
                                autohide={false}
                                isOpen={this.state.tooltipOpen}
                                toggle={this.toggle}
                            >
                                <div key={labelProps.name + "tooltip"} dangerouslySetInnerHTML={{ __html: tooltip }}></div>
                            </Tooltip>
                        </Fragment>
                    )}
                    {required && <i className="text-danger">*</i>}
                    &nbsp;
                </Label>
            );
        } else return "";
    }

    static propTypes = {
        caption: PropTypes.any,
        required: PropTypes.bool,
        labelWidth: PropTypes.number,
        onClick: PropTypes.func,
        colon: PropTypes.bool,
        labelLight: PropTypes.bool,
        tooltip: PropTypes.any,
        tooltipId: PropTypes.string,
    };

    static defaultProps = {
        required: false,
        labelWidth: 4,
        colon: true,
        tooltip: null,
    };
}

export default UniLabel;
