import React, { Fragment } from "react";
import { Input, Button, FormFeedback } from "reactstrap";
import PropTypes from "prop-types";
import UniLabel from "./UniLabel";
import ColInline from "./ColInline";
import { isValid } from "../../utils/forms";
import _ from "lodash";

class Uniput extends React.Component {
    renderUniLabel(size, labelFor) {
        const { tooltip, tooltipId, label, labelWidth, labelWidthLg, required, requiredShowOnly, labelRight, labelLight } =
            this.props;

        return (
            <UniLabel
                tooltip={tooltip}
                labelLight={labelLight}
                tooltipId={tooltipId}
                colon={!labelRight}
                caption={label}
                labelWidth={labelWidth}
                labelWidthLg={labelWidthLg}
                required={required || requiredShowOnly}
                size={size}
                for={labelFor}
                className="align-baseline"
            />
        );
    }

    render() {
        const {
            inputWidth,
            inputWidthLg,
            labelWidth,
            labelWidthLg,
            totalWidth,
            small,
            addOnIcon,
            label,
            required,
            requiredShowOnly,
            inline,
            addOnClick,
            showError,
            options,
            type,
            log,
            disabled,
            className,
            autoComplete,
            pattern,
            validationMessage,
            colClassName,
            isNumberCheck,
            labelRight,
            ...InputProps
        } = this.props;

        delete InputProps.value;
        delete InputProps.checked;
        delete InputProps.defaultChecked;
        delete InputProps.size;
        delete InputProps.labelLight;
        delete InputProps.tooltipId;

        let { value, size } = this.props;
        if (value === null) value = "";

        let checked = undefined;

        const emptyWidth = label !== "" ? totalWidth - inputWidth - labelWidth : 0;

        const addOn = addOnIcon !== undefined;

        if (type === "radio" || type === "checkbox") {
            checked = value;
            value = undefined;
        }

        if (!size) size = small ? "sm" : "";

        const id = _.uniqueId();

        return (
            <Fragment>
                {!labelRight && this.renderUniLabel(size, id)}
                <ColInline
                    inline={inline}
                    inputWidth={inputWidth}
                    inputWidthLg={inputWidthLg}
                    emptyWidth={emptyWidth}
                    addOn={addOn}
                    addOnSmall={small}
                    className={colClassName}
                >
                    <Input
                        id={id}
                        type={type}
                        value={value}
                        checked={checked}
                        invalid={
                            ((required || requiredShowOnly) && showError && !isValid(value, isNumberCheck)) ||
                            (validationMessage !== undefined && validationMessage.length > 0)
                        }
                        children={options}
                        disabled={disabled}
                        bsSize={type !== "checkbox" ? size : ""}
                        autoComplete={autoComplete}
                        className={"input-group-custom " + className}
                        pattern={pattern}
                        {...InputProps}
                    />
                    {addOn && (
                        <Button onClick={addOnClick} color="primary" disabled={disabled} className="form-check-input">
                            {addOnIcon}
                        </Button>
                    )}
                    <FormFeedback valid={!(validationMessage !== undefined && validationMessage.length > 0)} tooltip>
                        <div dangerouslySetInnerHTML={{ __html: validationMessage }} />
                    </FormFeedback>
                </ColInline>
                {labelRight && this.renderUniLabel(size, id)}
            </Fragment>
        );
    }

    static propTypes = {
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.any,
        inline: PropTypes.bool,
        inputWidth: PropTypes.number,
        labelWidth: PropTypes.number,
        totalWidth: PropTypes.number,
        children: PropTypes.object,
        disabled: PropTypes.bool,
        addOnIcon: PropTypes.object,
        addOnClick: PropTypes.func,
        small: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        showError: PropTypes.bool,
        tooltip: PropTypes.any,
        tooltipId: PropTypes.string,
        isNumberCheck: PropTypes.bool,
    };

    static defaultProps = {
        type: "text",
        label: "",
        value: "",
        inline: false,
        labelWidth: 4,
        totalWidth: 6,
        disabled: false,
        autoComplete: "disabled",
        isNumberCheck: false,
    };
}

export default Uniput;
