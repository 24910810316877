import React, { Fragment } from "react";
import { Col, Row, Alert, Form, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { fetch } from "whatwg-fetch";
import queryString from "query-string";
import GeoTypeahead, { DEFAULT_COUNTRY } from "../../core/components/inputs/GeoTypeahead";
import Uniput from "../../core/components/inputs/Uniput";
import { checkRequired, handleInputChange, getModel } from "../../core/utils/forms";
import SwitchInput from "../../core/components/inputs/SwitchInput";
import Tariffs from "./Tariffs";

const SAVE_URL = "api/CDEK/application/setApplicationSettings";
const GET_URL = "api/CDEK/application/GetApplicationSettings/";
const TARIFF2 = [
    { value: "136", title: "Посылка ПВЗ-ПВЗ", check: true, name: "pvzPvz" },
    { value: "137", title: "Посылка ПВЗ-Дверь ", check: true, name: "pvzDoor" },
    { value: "138", title: "Посылка Дверь-ПВЗ", check: false, name: "doorPvz" },
    {
        value: "139",
        title: "Посылка Дверь-Дверь",
        check: false,
        name: "doorDoor",
    },
    {
        value: "366",
        title: "Посылка дверь-постамат",
        check: false,
        name: "doorPost",
    },
    {
        value: "368",
        title: "Посылка склад-постамат",
        check: false,
        name: "storagePost",
    },
    {
        value: "234",
        title: "Экономичная посылка ПВЗ-ПВЗ",
        check: true,
        name: "pvzPvzEconom",
    },
    {
        value: "233",
        title: "Экономичная посылка ПВЗ-Дверь",
        check: true,
        name: "pvzDoorEconom",
    },
    {
        value: "232",
        title: "Экономичная посылка Дверь-ПВЗ",
        check: false,
        name: "doorPvzEconom",
    },
    {
        value: "378",
        title: "Экономичная посылка склад-постамат",
        check: false,
        name: "storagePostEconom",
    },
    {
        value: "291",
        title: "CDEK Express ПВЗ-ПВЗ",
        check: false,
        name: "pvzPvzCDEKExpresse",
    },
    {
        value: "294",
        title: "CDEK Express ПВЗ-Дверь",
        check: false,
        name: "pvzDoorCDEKExpresse",
    },
    {
        value: "295",
        title: "CDEK Express Дверь-ПВЗ",
        check: false,
        name: "doorPvzCDEKExpresse",
    },
    {
        value: "293",
        title: "CDEK Express Дверь-Дверь",
        check: false,
        name: "doorDoorCDEKExpresse",
    },
];

export class CDEKSettings extends React.Component {
    constructor(props) {
        super(props);
        this.required = [];
        this.model = [];

        this.handleModel = this.handleModel.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getModel = this.getModel.bind(this);
        this.submit = this.submit.bind(this);
        this.checkRequired = this.checkRequired.bind(this);
        this.handleChangeTariff = this.handleChangeTariff.bind(this);

        this.toggleModal = this.toggleModal.bind(this);

        this.state = {
            account: "",
            forTest: false,
            apiKey: "",
            contractNumber: "",
            city: [],
            shopName: "",
            shopAddress: "",
            phone: "",
            tariffs: TARIFF2,
            useOnePackage: false,
            vat: "notNDS",
            countDeliveryDay: "0",
            length: "0",
            width: "0",
            height: "0",
            defaultWeight: "0",
            defaultWeightFor: "OrderWeight",
            insurance: true,
            fittingClothesHome: false,
            partialDelivery: false,
            inspectionAttachment: false,
            hideTariffName: false,
            freeDelivery: "0",
            updateTracking: false,
            increaseCostFix: "0",
            increaseCostFromOrder: "0.75",
            increaseCostFromDelivery: "0",
            roundValues: "not",
            degreeRounding: "--",
            errorShow: false,
            modalOpen: false,
            isLoading: false,
            responseMessage: "",

            chooseFromList: false,

            showCourierDelivery: true,
            showPvzDelivery: true,
            showPostDelivery: true,
            showEconomDelivery: true,
            showExpressDelivery: true,
            createOrdersFromDoor: false,
        };
    }

    toggleModal() {
        this.setState({ modalOpen: !this.state.modalOpen });
    }

    componentDidMount() {
        if (
            this.props.location.search === undefined ||
            this.props.location.search === null ||
            this.props.location.search === ""
        ) {
            document.location.replace("https://cdek.global/modules/insales/docs");
        } else {
            //загружаем настройки , если они уже есть в нашей базе
            fetch(GET_URL + this.props.location.search)
                .then((response) => {
                    return response.json();
                })
                .then((option) => {
                    const tariffs = this.state.tariffs;

                    if (option.tariffs !== undefined && option.tariffs !== null) {
                        for (let i = 0; i < tariffs.length; i++) {
                            tariffs[i].check = option.tariffs.indexOf(tariffs[i].value) >= 0;
                        }
                    }
                    option.tariffs = tariffs;
                    this.setState(option);
                });
        }
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    handleModel(node) {
        if (node !== null) {
            this.model.push(node);

            // Сохраняем то что обязательно в специальную коллекцию
            if (node.props.required) this.required.push(node);
        }
    }

    getModel() {
        let result = getModel(this.model, this.state);
        result.userInformation = queryString.parse(this.props.location.search);
        result.tariffs = [];
        for (let i = 0; i < this.state.tariffs.length; i++) {
            if (this.state.tariffs[i].check) {
                result.tariffs.push(this.state.tariffs[i].value);
            }
        }
        return result;
    }

    checkRequired() {
        let res = checkRequired(this.required, this.state);

        if (!res) this.setState({ showError: true });

        return res;
    }

    submit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }

        const body = this.getModel();
        this.setState({
            isLoading: true,
        });

        fetch(SAVE_URL + this.props.location.search, {
            method: "post",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(body),
        })
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                const message = result.errors.length > 0 ? `Ошибка : ${result.errors[0]}` : "";
                this.setState({
                    isLoading: false,
                    responseMessage: result.success
                        ? "Данные успешно сохранены. Модуль настроен корректно!"
                        : `Настройки сохранены. Но данные заполнены не полностью или не корректно. ${message}`,
                });
                this.toggleModal();
            });
    }

    handleChange(event) {
        const { value, name } = handleInputChange(event);

        this.setState({
            [name]: value,
            errorShow: false,
            errorMsg: "",
        });
    }

    handleChangeTariff(event, key) {
        const { value } = handleInputChange(event);
        const tariff = this.state.tariffs[key];
        tariff.check = value;
        this.setState({ ...this.state });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    static costType() {
        return (
            <Fragment>
                <option value="Ruble">рубль(-ей)</option>
                <option value="Percent">процент(-ов)</option>
            </Fragment>
        );
    }

    static vATOptions() {
        return (
            <Fragment>
                <option value="notNDS">БЕЗ НДС</option>
                <option value="0">НДС 0%</option>
                <option value="10">НДС 10%</option>
                <option value="20">НДС 20%</option>
            </Fragment>
        );
    }

    static roundValuesOptions() {
        return (
            <Fragment>
                <option value="not">нет</option>
                <option value="roundDown">в меньшую сторону</option>
                <option value="roundUp">в большую сторону</option>
            </Fragment>
        );
    }

    static degreeRoundingOptions() {
        return (
            <Fragment>
                <option value="0">--</option>
                <option value="1">1</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </Fragment>
        );
    }

    render() {
        return (
            <Fragment>
                <Row className="mt-5 text-center">
                    <Col className="align-bottom">
                        <img className="align-bottom ps-3" height="60" alt="СДЭК лого" src="img\CDEK-logo.png" />
                        <span className="display-4 fw-bold align-bottom me-3 ms-1">+</span>
                        <img height="84" alt="СДЭК лого" src="img\InSales-logo.svg" />
                    </Col>
                </Row>
                <Row className="mt-5 mb-2">
                    <Col>
                        <Row>
                            <h2>НАСТРОЙКА ПРОФИЛЯ СДЭК</h2>
                        </Row>
                        <Row>
                            <span>
                                Для настройки интеграции у вас должен быть действующи договор со СДЭК. Если у вас его еще нет или
                                вы не уверены что он действует, напишите нам через&nbsp;
                                <a href="https://cdek.global/insales_connect" target="_blank" rel="noopener noreferrer">
                                    эту форму
                                </a>
                                !
                            </span>
                        </Row>
                        <Row className="my-2">
                            <span>
                                Все вопросы по работе модуля вы можете задать нам по email - &nbsp;
                                <a href="mailto:support@cdek.global?subject=Вопрос по модулю интеграции InSales">
                                    support@cdek.global
                                </a>
                                .
                            </span>
                        </Row>
                    </Col>
                </Row>
                <Form onSubmit={this.submit}>
                    <Row className="gray_bg p-3">
                        <Col>
                            <Row>
                                <h3>Аккаунт интеграции</h3>
                            </Row>
                            <Row className="py-1 px-3 form-check form-switch">
                                <SwitchInput
                                    className="ps-0 ms-0 py-2"
                                    value={this.state.forTest}
                                    onChange={this.handleChange}
                                    name="forTest"
                                    label="Подключить тестовый договор (для тестирования модуля)"
                                    id="forTest"
                                    ref={this.handleModel}
                                />
                            </Row>
                            {this.state.forTest && (
                                <Row className="text-muted">
                                    <Col sm={12} md={12} lg={5} xl={4}>
                                        ТЕСТОВЫЙ контракт имеет ряд ограничений:
                                    </Col>
                                    <Col>
                                        <ul>
                                            <li className="text-danger fw-bold">
                                                заказы могут обрабатываться в СДЭК до 2-х минут,{" "}
                                            </li>
                                            <li>нет возможности вызвать курьера,</li>
                                            <li>не работает трекинг заказа</li>
                                            <li className="text-danger fw-bold">не передаются заказы с доставкой до ПВЗ</li>
                                        </ul>
                                    </Col>
                                </Row>
                            )}

                            {!this.state.forTest && (
                                <Fragment>
                                    <Col>
                                        <Row>
                                            <span>
                                                Номер договора: <i className="text-danger">*</i>
                                            </span>
                                        </Row>
                                        <Row className="g-0">
                                            <Uniput
                                                onChange={this.handleChange}
                                                ref={this.handleModel}
                                                name={"contractNumber"}
                                                value={this.state.contractNumber}
                                                inline
                                                colClassName="px-0 ms-0"
                                                placeholder="Укажите номер договора..."
                                                showError={this.state.showError}
                                            />
                                        </Row>
                                    </Col>
                                    <Row className="mt-2">
                                        <Col>
                                            <Row>
                                                <span>
                                                    Логин (Account): <i className="text-danger">*</i>
                                                </span>
                                            </Row>
                                            <Row className="g-0">
                                                <Uniput
                                                    onChange={this.handleChange}
                                                    ref={this.handleModel}
                                                    name={"account"}
                                                    value={this.state.account}
                                                    colClassName=" px-0 ms-0"
                                                    placeholder="Укажите логин (Account)..."
                                                    showError={this.state.showError}
                                                />
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <span>
                                                    Пароль (Secure password): <i className="text-danger">*</i>
                                                </span>
                                            </Row>
                                            <Row className="g-0">
                                                <Uniput
                                                    onChange={this.handleChange}
                                                    ref={this.handleModel}
                                                    name={"apiKey"}
                                                    value={this.state.apiKey}
                                                    inline
                                                    colClassName="px-0 ms-0"
                                                    placeholder="Укажите Пароль (Secure password)..."
                                                    showError={this.state.showError}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="small_text text-muted mt-1">
                                        <Col className="px-3">
                                            <Row>
                                                <span className="text-danger">
                                                    ВНИМАНИЕ: Здесь нужно указывать Логин и Пароль <b>ДЛЯ ИНТЕГРАЦИИ</b>, а{" "}
                                                    <b>НЕ ОТ</b> вашего личного кабинета!
                                                </span>
                                            </Row>
                                            <span>
                                                Если у вас еще нет Логина и Пароля для интаграции - вы можете их запросить&nbsp;
                                                <a href="mailto:integrator@cdek.ru?subject=%D0%9F%D1%80%D0%BE%D1%88%D1%83%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%B8%D1%82%D1%8C%20API%20Key%20%D0%B4%D0%BB%D1%8F%20%D0%BC%D0%BE%D0%B5%D0%B3%D0%BE%20%D0%B4%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%D0%B0&body=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C!%0D%0A%0D%0A%D0%9F%D1%80%D0%BE%D1%88%D1%83%20%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%B8%D1%82%D1%8C%20API%20Key%20%D0%B4%D0%BB%D1%8F%20%D0%B4%D0%BE%D1%81%D1%82%D1%83%D0%BF%D0%B0%20%D0%BA%20%D0%B8%D0%BD%D1%82%D0%B5%D0%B3%D1%80%D0%B0%D1%86%D0%B8%D0%B8%20%D0%B4%D0%BB%D1%8F%20%D0%BC%D0%BE%D0%B5%D0%B3%D0%BE%20%D0%B4%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%D0%B0%20%D0%A1%D0%94%D0%AD%D0%9A%20(%5B%D0%9D%D0%9E%D0%9C%D0%95%D0%A0%20%D0%94%D0%9E%D0%93%D0%9E%D0%92%D0%9E%D0%A0%D0%90%5D).%20%D0%A1%D0%BF%D0%B0%D1%81%D0%B8%D0%B1%D0%BE!">
                                                    написав письмо в техническую поддержку СДЭК
                                                </a>
                                                .
                                            </span>
                                        </Col>
                                    </Row>
                                </Fragment>
                            )}
                        </Col>
                    </Row>
                    <Row className="p-3">
                        <Row>
                            <h3>Магазин</h3>
                        </Row>
                        <Row className="g-0">
                            <Col className="ps-3 pe-3">
                                <Row>
                                    <span>
                                        Название магазина: <i className="text-danger">*</i>
                                    </span>
                                </Row>
                                <Row className="g-0">
                                    <Uniput
                                        onChange={this.handleChange}
                                        ref={this.handleModel}
                                        colClassName="px-0 ms-0"
                                        name={"shopName"}
                                        value={this.state.shopName}
                                        inline
                                        placeholder="Укажите название магазина..."
                                        showError={this.state.showError}
                                    />
                                </Row>
                                <Row className="small_text text-muted px-3">Название магазина будет отображено на накладной</Row>

                                <Row>
                                    <span>
                                        Город отправки: <i className="text-danger">*</i>
                                    </span>
                                </Row>
                                <Row className="">
                                    <GeoTypeahead
                                        type="city"
                                        onChange={this.handleChange}
                                        ref={this.handleModel}
                                        value={this.state.city}
                                        parentValue={DEFAULT_COUNTRY}
                                        colClassName=" px-0 ms-0"
                                        name="city"
                                        label=""
                                    />
                                </Row>
                            </Col>
                            <Col className="pe-3">
                                <Row>
                                    <span>
                                        Номер телефона: <i className="text-danger">*</i>
                                    </span>
                                </Row>
                                <Row className="g-0">
                                    <Uniput
                                        onChange={this.handleChange}
                                        ref={this.handleModel}
                                        colClassName=" px-0 ms-0"
                                        name={"phone"}
                                        value={this.state.phone}
                                        inline
                                        placeholder="Укажите номер телефона..."
                                        showError={this.state.showError}
                                    />
                                </Row>
                                <Row className="small_text text-muted px-3">Контактный телефон по вопросам доставки</Row>
                                <Row>
                                    <span>
                                        Адрес магазина: <i className="text-danger">*</i>
                                    </span>
                                </Row>
                                <Row className="g-0">
                                    <Uniput
                                        onChange={this.handleChange}
                                        ref={this.handleModel}
                                        colClassName=" px-3 ms-0"
                                        name={"shopAddress"}
                                        value={this.state.shopAddress}
                                        // inline
                                        placeholder="Укажите адрес магазина..."
                                        showError={this.state.showError}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <Col className="px-3">
                            <Row className="small_text text-muted px-3">
                                Город отправки и адрес магазина необходимы для расчета цены доставки и для возможности вызывать
                                курьера
                            </Row>
                        </Col>
                    </Row>
                    <Tariffs
                        handleChangeTariff={this.handleChangeTariff}
                        handleChange={this.handleChange}
                        handleModel={this.handleModel}
                        hideTariffName={this.state.hideTariffName}
                        chooseFromList={this.state.chooseFromList}
                        tariffs={this.state.tariffs}
                        showCourierDelivery={this.state.showCourierDelivery}
                        showPvzDelivery={this.state.showPvzDelivery}
                        showPostDelivery={this.state.showPostDelivery}
                        showEconomDelivery={this.state.showEconomDelivery}
                        showExpressDelivery={this.state.showExpressDelivery}
                        createOrdersFromDoor={this.state.createOrdersFromDoor}
                    />
                    <Row className="p-3">
                        {" "}
                        <Col>
                            <Row>
                                <h3>Упаковка</h3>
                            </Row>
                            <Row className="py-1 px-0 form-check form-switch">
                                <SwitchInput
                                    className="ps-0 ms-0 py-2"
                                    value={this.state.useOnePackage}
                                    onChange={this.handleChange}
                                    name="useOnePackage"
                                    label="Использовать универсальную упаковку"
                                    id="useOnePackage"
                                    ref={this.handleModel}
                                />
                            </Row>
                            <Row className="small_text text-muted">
                                Если при отправке содержимое заказа упаковывается в одно грузоместо, то установите эту опцию. В
                                этом случае для расчета цены и при передаче заказа в СДЭК будет передаваться одно грузоместо с
                                указанными ниже параметрами. Если вы используете разную упаковку - укажите здесь значение
                                наибольшей из них
                            </Row>
                            <Row className="mt-3">Габариты упаковки</Row>
                            <Row className="mt-1">
                                <Col className="px-3">
                                    <Row>Длина (см):</Row>
                                    <Row>
                                        <Uniput
                                            onChange={this.handleChange}
                                            ref={this.handleModel}
                                            colClassName=" ps-0 ms-0"
                                            name={"length"}
                                            value={this.state.length}
                                            inline
                                            placeholder="Укажите длину..."
                                            showError={this.state.showError}
                                            disabled={!this.state.useOnePackage}
                                            type="number"
                                        />
                                    </Row>
                                </Col>
                                <Col className="px-3">
                                    <Row>Ширина (см):</Row>
                                    <Row>
                                        <Uniput
                                            onChange={this.handleChange}
                                            ref={this.handleModel}
                                            colClassName=" ps-0 ms-0"
                                            name={"width"}
                                            value={this.state.width}
                                            inline
                                            placeholder="Укажите ширину..."
                                            showError={this.state.showError}
                                            disabled={!this.state.useOnePackage}
                                            type="number"
                                        />
                                    </Row>
                                </Col>
                                <Col className="px-3">
                                    <Row>Высота (см):</Row>
                                    <Row>
                                        <Uniput
                                            onChange={this.handleChange}
                                            ref={this.handleModel}
                                            colClassName=" ps-0 ms-0"
                                            name={"height"}
                                            value={this.state.height}
                                            inline
                                            placeholder="Укажите высоту..."
                                            showError={this.state.showError}
                                            disabled={!this.state.useOnePackage}
                                            type="number"
                                        />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="text-muted small_text">
                                Необходимо учитывать, что при передаче заказов в наши ПВЗ будет происходить замер весовых и
                                габаритных характеристик, на основании которых и будет рассчитываться стоимость доставки. Исходя
                                из размеров заказа стоимость доставки может быть увеличена или уменьшена.
                            </Row>
                            <Row className="mt-2">Вес по-умолчанию (гр):</Row>
                            <Row>
                                <Col ms={4} md={6} className="px-3">
                                    <Row className="py-1">
                                        <Uniput
                                            onChange={this.handleChange}
                                            ref={this.handleModel}
                                            colClassName=" ps-0 ms-0"
                                            name={"defaultWeight"}
                                            value={this.state.defaultWeight}
                                            inline
                                            placeholder="Укажите вес..."
                                            showError={this.state.showError}
                                            type="number"
                                        />
                                    </Row>
                                </Col>
                                <Col className="px-3">
                                    <Row className="form-check d-flex flex-nowrap ps-0">
                                        <Uniput
                                            onChange={this.handleChange}
                                            ref={this.handleModel}
                                            type="radio"
                                            className="form-check-input p-0 mt-1 ms-0"
                                            name="defaultWeightFor"
                                            value={this.state.defaultWeightFor === "ProductWeight"}
                                            id="ProductWeight"
                                        />
                                        <label htmlFor="ProductWeight" className="form-check-label ps-2 m-0">
                                            Вес по-умолчанию задан для товара
                                        </label>
                                    </Row>
                                    <Row className="d-flex flex-nowrap ps-0">
                                        <Uniput
                                            onChange={this.handleChange}
                                            ref={this.handleModel}
                                            type="radio"
                                            className="form-check-input p-0 mt-1 ms-0"
                                            name="defaultWeightFor"
                                            value={this.state.defaultWeightFor === "OrderWeight"}
                                            id="OrderWeight"
                                        />
                                        <label htmlFor="OrderWeight" className="form-check-label ps-2 m-0">
                                            Вес по-умолчанию задан для заказа
                                        </label>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="gray_bg p-3">
                        <Col>
                            <Row>
                                <h3>Дополнительные услуги</h3>
                            </Row>
                            <Row className="py-1 px-0 form-check form-switch">
                                <SwitchInput
                                    className="ps-0 ms-0 py-2"
                                    value={this.state.insurance}
                                    onChange={this.handleChange}
                                    name="insurance"
                                    label="Страхование"
                                    id="insurance"
                                    disabled={true}
                                    ref={this.handleModel}
                                />
                            </Row>
                            <Row className="text-muted small_text">
                                Дополнительная услуга "Страхование" подключена автоматически для всех заказов
                            </Row>
                            <Row className="py-1 px-0 form-check form-switch">
                                <SwitchInput
                                    className="ps-0 ms-0 py-2"
                                    value={this.state.fittingClothesHome}
                                    onChange={this.handleChange}
                                    name="fittingClothesHome"
                                    label="Примерка на дому"
                                    id="fittingClothesHome"
                                    ref={this.handleModel}
                                />
                            </Row>
                            <div>
                                <Row className="py-1 px-0 form-check form-switch">
                                    <SwitchInput
                                        className="ps-0 ms-0 py-2"
                                        value={this.state.partialDelivery}
                                        onChange={this.handleChange}
                                        name="partialDelivery"
                                        label="Частичная доставка"
                                        id="partialDelivery"
                                        ref={this.handleModel}
                                    />
                                </Row>
                            </div>
                            <Row className="py-1 px-0 form-check form-switch">
                                <SwitchInput
                                    className="ps-0 ms-0 py-2"
                                    value={this.state.inspectionAttachment}
                                    onChange={this.handleChange}
                                    name="inspectionAttachment"
                                    label="Запрет осмотра вложения"
                                    id="inspectionAttachment"
                                    ref={this.handleModel}
                                />
                            </Row>
                        </Col>
                    </Row>

                    <Row className="p-3">
                        <Col>
                            <Row>
                                <h3>Импортировать статусы заказов</h3>
                            </Row>
                            <Row className="py-1 px-0 form-check form-switch">
                                <SwitchInput
                                    className="ps-0 ms-0 py-2"
                                    value={this.state.updateTracking}
                                    onChange={this.handleChange}
                                    name="updateTracking"
                                    label="Обновлять заказы в системе, при изменении трекинга СДЭК"
                                    id="updateTracking"
                                    ref={this.handleModel}
                                />
                            </Row>
                            <Row className="text-muted small_text">
                                Данная опция позволяет обновлять статусы заказов в магазине InSales при изменении статусов заказа
                                в службе доставки СДЭК
                            </Row>
                        </Col>
                    </Row>

                    <Row className="gray_bg p-3">
                        <Col>
                            <Row>
                                <h3>Модификация стоимости доставки и сроков</h3>
                            </Row>
                            <Row className="text-muted small_text">
                                Стоимость доставки можно увеличить на фиксированную сумму, на процент от стоимости доставки по
                                калькулятору или на процент от стоимости всего заказа. Более подробное описание смотрите в
                                инструкции по установке модуля.
                            </Row>
                            <Row className="mt-1">
                                <Col md={5} lg={4}>
                                    Увеличить на фиксированную сумму (РУБ):
                                </Col>
                                <Col md={2} lg={3}>
                                    <Uniput
                                        colClassName=" ps-0 ms-0"
                                        onChange={this.handleChange}
                                        ref={this.handleModel}
                                        name={"increaseCostFix"}
                                        value={this.state.increaseCostFix}
                                        inline
                                        showError={this.state.showError}
                                        type="number"
                                    />
                                </Col>
                                <Col className="text-muted small_text">
                                    Например, вы можете увеличить стоимость доставки на стоимость комплектации заказа.
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={5} lg={4}>
                                    Увеличить на процент от цены заказа (%):
                                </Col>
                                <Col md={2} lg={3}>
                                    <Uniput
                                        colClassName=" ps-0 ms-0"
                                        onChange={this.handleChange}
                                        ref={this.handleModel}
                                        name={"increaseCostFromOrder"}
                                        value={this.state.increaseCostFromOrder}
                                        inline
                                        showError={this.state.showError}
                                        type="number"
                                    />
                                </Col>
                                <Col className="text-muted small_text">
                                    Например, вы можете увеличить % страховки (обычно это 0,75%) или на % РКО (обычно 2%). Точную
                                    сумму можно посмотреть в вашем договоре СДЭК.
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={5} lg={4}>
                                    Увеличить на процент от цены доставки (%):
                                </Col>
                                <Col md={2} lg={3}>
                                    <Uniput
                                        colClassName=" ps-0 ms-0"
                                        onChange={this.handleChange}
                                        ref={this.handleModel}
                                        name={"increaseCostFromDelivery"}
                                        value={this.state.increaseCostFromDelivery}
                                        inline
                                        showError={this.state.showError}
                                        type="number"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={6}>
                                    <Row className="px-3">Округлять стоимость доставки:</Row>
                                    <Row>
                                        <Uniput
                                            colClassName=" px-3 ms-0"
                                            onChange={this.handleChange}
                                            ref={this.handleModel}
                                            name="roundValues"
                                            type="select"
                                            value={this.state.roundValues}
                                            options={CDEKSettings.roundValuesOptions()}
                                            inline
                                            showError={this.props.showError}
                                        />
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row className="px-3">Степень округления:</Row>
                                    <Row>
                                        <Uniput
                                            colClassName="px-3 ms-0"
                                            onChange={this.handleChange}
                                            ref={this.handleModel}
                                            name="degreeRounding"
                                            type="select"
                                            value={this.state.degreeRounding}
                                            options={CDEKSettings.degreeRoundingOptions()}
                                            inline
                                            showError={this.props.showError}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={6}>
                                    <Row className="px-3">Увеличить время доставки на __ дней:</Row>
                                    <Row>
                                        <Uniput
                                            onChange={this.handleChange}
                                            ref={this.handleModel}
                                            colClassName="px-3 ms-0"
                                            name={"countDeliveryDay"}
                                            value={this.state.countDeliveryDay}
                                            inline
                                            showError={this.state.showError}
                                            type="number"
                                        />
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className="px-3">НДС для стоимости доставки:</Row>
                                    <Row>
                                        <Uniput
                                            onChange={this.handleChange}
                                            ref={this.handleModel}
                                            colClassName=" px-3 ms-0"
                                            name="vat"
                                            type="select"
                                            value={this.state.vat}
                                            options={CDEKSettings.vATOptions()}
                                            inline
                                            showError={this.props.showError}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Alert className="mt-1" fade={false} color="danger" isOpen={this.state.errorShow}>
                        {this.state.errorShow && this.state.errorMsg}
                    </Alert>
                    <Row className="my-2 align-content-center">
                        <Col md={12} className="m-auto">
                            <Row>
                                <Button disabled={this.state.isLoading} type="submit" color="success">
                                    Сохранить настройки
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Form>

                <div>
                    <Modal isOpen={this.state.modalOpen} toggle={this.toggleModal}>
                        <ModalHeader toggle={this.toggleModal} />
                        <ModalBody>{this.state.responseMessage}</ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.toggleModal}>
                                {" "}
                                Ок{" "}
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </Fragment>
        );
    }
}
