import React from "react";
import SwitchInput from "../../core/components/inputs/SwitchInput";
import { Row, Col } from "reactstrap";

class TariffSettings extends React.Component {
    render() {
        const {
            showCourierDelivery,
            showPvzDelivery,
            showEconomDelivery,
            showPostDelivery,
            createOrdersFromDoor,
            handleChange,
            handleModel,
        } = this.props;

        return (
            <Row className="gray_bg ">
                <Col>
                    <Row className="py-1 px-0 form-check form-switch">
                        <SwitchInput
                            className="ps-0 ms-0 py-2"
                            value={showCourierDelivery}
                            onChange={handleChange}
                            name="showCourierDelivery"
                            label="Показывать доставку курьером"
                            id="showCourierDelivery"
                            ref={handleModel}
                        />
                    </Row>
                    <Row className="py-1 px-0 form-check form-switch">
                        <SwitchInput
                            className="ps-0 ms-0 py-2"
                            value={showPvzDelivery}
                            onChange={handleChange}
                            name="showPvzDelivery"
                            label="Показывать доставку до пунктов выдачи СДЭК"
                            id="showPvzDelivery"
                            ref={handleModel}
                        />
                    </Row>
                    <Row className="py-1 px-0 form-check form-switch">
                        <SwitchInput
                            className="ps-0 ms-0 py-2"
                            value={showPostDelivery}
                            onChange={handleChange}
                            name="showPostDelivery"
                            label="Показывать доставку до почтаматов"
                            id="showPostDelivery"
                            ref={handleModel}
                        />
                    </Row>
                    <Row className="small_text text-muted">
                        Почтоматы имеют ограничения на доставку по габаритом и весу, а также не позволяют осуществлять осмотр
                        вложения, частичную доставку и примерку. Если заказ не подходит для доставки в почтамат - то почтаматы
                        будут автоматически удалены из списка пунктов выдачи при отображении пользователю.
                    </Row>
                    <Row className="py-1 px-0 form-check form-switch">
                        <SwitchInput
                            className="ps-0 ms-0 py-2"
                            value={showEconomDelivery}
                            onChange={handleChange}
                            name="showEconomDelivery"
                            label="Отображать эконом доставку"
                            id="showEconomDelivery"
                            ref={handleModel}
                        />
                    </Row>
                    <Row className="small_text text-muted">
                        Эконом доставка как правило дешевле, но дольше по срокам. Учтите, что эконом доставка доступна не во всех
                        регионах! При выборе этой опции и наличии эконом доставки в выбраном городе - пользователю будет
                        отображаться два варианта на выбор.
                    </Row>
                    <Row className="py-1 px-0 form-check form-switch">
                        <SwitchInput
                            className="ps-0 ms-0 py-2"
                            value={createOrdersFromDoor}
                            onChange={handleChange}
                            name="createOrdersFromDoor"
                            label="Создавать заказы от двери"
                            id="createOrdersFromDoor"
                            ref={handleModel}
                        />
                    </Row>
                    <Row className="small_text text-muted">
                        При выборе этой опции будут использоваться тарифы СДЭК от Двери. В противном случае, будут использоваться
                        тарифы от Склада. Цена тарифов от Двери выше и их рекомендуется использовать если количество отправлений у
                        вас не выше 2-х в день. В противном случае рекомендуется отдельно вызывать курьера для забора и тарифы от
                        Склада. Также при выборе этой опции вам будут не доступна доставка до Постоматов и экономичные тарифы по
                        некоторым направлениям.
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default TariffSettings;
