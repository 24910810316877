import React, { Fragment } from "react";
import { Col } from "reactstrap";
import PropTypes from "prop-types";

export default class ColInline extends React.Component {
    render() {
        const { inline, addOn } = this.props;
        const emptyWidth = !inline && addOn ? 0 : this.props.emptyWidth;

        const className =
            (this.props.className !== undefined ? this.props.className : "") +
            (addOn ? " input-group" : "") +
            (addOn && this.props.addOnSmall ? " input-group-sm" : "");

        if (inline || addOn)
            return (
                <Fragment>
                    <Col xs={this.props.inputWidth} lg={this.props.inputWidthLg} className={className}>
                        {this.props.children}
                    </Col>
                    {emptyWidth > 0 && <Col xs={emptyWidth} />}
                </Fragment>
            );
        else return <Fragment>{this.props.children}</Fragment>;
    }

    static propTypes = {
        inline: PropTypes.bool,
        inputWidth: PropTypes.number,
        totalWidth: PropTypes.number,
        className: PropTypes.string,
        addOn: PropTypes.bool,
        addOnSmall: PropTypes.bool,
    };

    static defaultProps = {
        inline: false,
        emptyWidth: 3,
        addOn: false,
    };
}
