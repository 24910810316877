import { Input } from "reactstrap";
import React from "react";

class SwitchInput extends React.Component {
    render() {
        const { disabled, label, id, value, name, onChange, className } = this.props;

        return (
            <span>
                <Input
                    className={`form-check-input ${className}`}
                    type="switch"
                    id={id}
                    name={name}
                    disabled={disabled}
                    checked={value}
                    onChange={onChange}
                />

                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                    {label}
                </label>
            </span>
        );
    }
}

export default SwitchInput;
