import React from "react";
import { Row, Col } from "reactstrap";
import Uniput from "../../core/components/inputs/Uniput";

class TariffList extends React.Component {
    render() {
        const { tariffs, handleChangeTariff } = this.props;

        return (
            <div>
                <Row className="mt-2">
                    <Col>
                        Выбраны, которые будут доступны клиенту для выбора:
                        <i className="text-danger">*</i>
                    </Col>
                </Row>
                <div className="tariffs ps-3">
                    {tariffs !== undefined &&
                        tariffs !== null &&
                        tariffs.map((item, key) => (
                            <Row key={key}>
                                <Col className="form-check-inline align-items-start px-0 me-0">
                                    <Uniput
                                        className="mt-1"
                                        onChange={(event) => handleChangeTariff(event, key)}
                                        type="checkbox"
                                        name={item.name}
                                        id={item.value}
                                        value={item.check}
                                    />
                                    <label htmlFor={item.value} size="sm" className="ps-0 mb-0 ms-2">
                                        {item.title}
                                    </label>
                                </Col>
                            </Row>
                        ))}
                </div>
                <Row className="small_text text-muted ps-3">ПВЗ - Пункт Выдачи Заказов СДЭК</Row>
            </div>
        );
    }
}

export default TariffList;
